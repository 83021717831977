@import 'variable';

.mat-button.ake-icon-button {
  height: $large-button-size;
  width: $large-button-size;
  line-height: $large-button-size;
  border-radius: 3px;
  color: #172b53;
  border-color: transparent;
  background-color: transparent;
  min-width: $large-button-size;
  padding: 0;

  mat-icon {
    font-size: $small-icon-size;
    height: $small-icon-size;
    width: $small-icon-size;
    line-height: $small-icon-size;
    color: black;
  }

  &:hover {
    //no hover effect for now
    //background-color: #ffeead;
  }
}

.mat-button.ake-small-icon-button {
  height: $small-button-size;
  width: $small-button-size;
  min-width: $small-button-size;
  line-height: $small-button-size;
}

.content-holder {
  //width: 100%;
  //max-width: 100%;
  //margin: 16px 0;
  //display: flex;
  //flex-direction: column;
  //
  //padding: $main-padding-mobile;
  //
  //@include bigger-than(MD) {
  //  margin-left: 151px;
  //  padding: 0;
  //}
  //
  //@include bigger-than(LG) {
  //  max-width: 80%;
  //}
}

.content-title {
  font-size: 2em;
  line-height: 40px;
  font-weight: normal;
}

.mat-expansion-panel:not(.dummy-specificity-class):not(.dummy-specificity-class) .mat-expansion-panel-header:hover {
  background-color: white !important;
}
.back-button {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
}

.back-button-text {
  font-size: 20px;
  color: black;
}

.mat-select-panel {
  background: #fff;
  .mat-option-text {
    color: black;
  }
}

.cdk-overlay-container .cdk-overlay-backdrop-showing {
  background-color: #0000003d;
  opacity: 0.5 !important;
}

.mat-select-panel:not([class*='mat-elevation-z']) {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

.mat-select-panel-wrap .mat-option {
  border-bottom: 1px solid #e2e5e8;
}

.mat-select-panel-wrap .mat-select-panel .mat-option.mat-selected:not(.mat-option-disabled) {
  background: #ffe923;
  color: #000;
}
