/* You can add global styles to this file, and also import other style files */

@use '../node_modules/@angular/material' as mat;
@import 'material-icons/iconfont/material-icons.css';

@import 'src/style/layout';

html,
body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: $ake-font-family;
}

ul > li {
  line-height: 1.6rem;
}

@include mat.core;

$ake-theme-typography: mat.define-typography-config(
  $font-family: $ake-font-family
);
$ake-blue-palette: (
  50: $ake-very-light-gray,
  100: $ake-pale-blue,
  200: $ake-pale-blue,
  300: $ake-blue,
  400: $ake-blue,
  500: $ake-blue,
  600: $ake-blue,
  700: $ake-blue,
  800: $ake-dark-blue,
  900: $ake-dark-blue,
  A100: $ake-blue,
  A200: $ake-blue,
  A400: $ake-blue,
  A700: $ake-dark-blue,
  contrast: (
    50: $ake-black,
    100: $ake-black,
    200: $ake-black,
    300: $ake-black,
    400: $ake-black,
    500: $ake-very-light-gray,
    600: $ake-very-light-gray,
    700: $ake-very-light-gray,
    800: $ake-very-light-gray,
    900: $ake-very-light-gray,
    A100: $ake-black,
    A200: $ake-very-light-gray,
    A400: $ake-very-light-gray,
    A700: $ake-very-light-gray
  )
);
$ake-theme-primary: mat.define-palette($ake-blue-palette, 500);
$ake-theme-blue: mat.define-light-theme(
  (
    color: (
      primary: $ake-theme-primary,
      accent: $ake-theme-primary
    ),
    typography: $ake-theme-typography
  )
);

@include mat.all-component-themes($ake-theme-blue);

//customize the mat-expansion-panel to match the akelius website
.mat-accordion {
  &.default-max-width {
    display: block;
    margin: 10px 0;
    width: 100%;
    box-shadow: none;
  }
}

.mat-expansion-panel {
  &:not([class*='mat-elevation-z']) {
    box-shadow: none !important;
    border-radius: 0;

    & > * {
      font-size: $font-size-default;
    }

    .mat-expansion-panel-header {
      padding: 0 3px 0;
    }

    .mat-expansion-indicator::after {
      color: $ake-black;
    }

    .mat-expansion-panel-body {
      padding: 0 0 16px;
    }
  }

  & + .mat-expansion-panel {
    border-top: 1px solid $ake-line-grey;
  }

  mat-panel-title {
    font-size: $font-size-title;
  }
}

.example-headers-align .mat-expansion-panel-header-title,
.example-headers-align .mat-expansion-panel-header-description {
  flex-basis: 0;
}

.example-headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

.example-headers-align .mat-form-field + .mat-form-field {
  margin-left: 8px;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0.75em 0.75em 0 0 !important;
}

.dynamic-link {
  color: black;
}

.teaser {
  margin: 16px 0;
  max-width: 100%;
  height: auto;
}
