/* Break points */
$breakpoints: (
  'XXS': 390px,
  'XS': 426px,
  'SM': 576px,
  'MD': 768px,
  'LG': 992px,
  'XL': 1200px,
  'XXL': 1500px
);

$content-width: 600px;
$bar-border: 1px solid #e2e5e8;
$title-margin: 20px 0;

/* Spacing */
$space: 8px;
$space-half: 8px * 0.5;
$space-double: 8px * 2;

$extra-huge-spacing: $space * 8;
$huge-spacing: $space * 4;
$extra-large-spacing: $space * 3;
$large-spacing: $space * 2;
$small-spacing: $space;
$tiny-spacing: $space * 0.5;

/* button */
$small-button-size: 32px;
$small-icon-size: 32px;
$large-button-size: 48px;
$main-padding-mobile: 0 15px;

/* colors */
$ake-dark-blue: #172b53;
$ake-medium-blue: #2c6685;
$ake-blue: #357ca2;
$ake-pale-blue: #a3c3d4;
$ake-text-grey: #b0bac1;
$ake-text-dark-grey: #67696a;
$ake-line-grey: #e2e5e8;
$ake-very-light-gray: #f5f5f5;
$ake-black: #000;
$ake-white: #fff;

/* fonts */
$font-size-title: 24px;
$font-size-default: 16px;
$ake-font-family: Verdana, 'Helvetica Neue', sans-serif;
